import React, { Fragment } from "react";
import { Link } from "gatsby";
import Layout from "../../layout/Layout";
// import EmployeeDetails from "../Consultation/ConsultationSidebar/EmployeeDetails"
import Employee from "../Consultation/ConsultationForm/Employee";

import { Formik, Form } from "formik";
import Panel from "../Templates/Forms/Panel";

import HospitalInformation from "./MedcertForm/HospitalInformation";
import MedcertInformation from "./MedcertForm/MedcertInformation";
import MedcertStatus from "./MedcertForm/MedcertStatus";

import classNames from "classnames";
import {
  faNotesMedical,
  faHospital,
  faCheckDouble,
  faUserTie,
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

import { MedcertValidationSchema } from "../Templates/Forms/ValidationSchema";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomField from "../Templates/CustomField/CustomField";

const handleKeyDown = event => {
  if (event.key === "Enter") event.preventDefault();
};

const MedcertForm = ({
  handleSubmit,
  appContext,
  isLoading,
  customFields,
  emptyRequiredCustomFields,
}) => {
  let staffId = "";
  if (typeof window !== `undefined`) {
    staffId = window.sessionStorage.getItem("staffId");
  }
  return (
    <Formik
      initialValues={{
        employee: { firstName: "" },
        dateIssued: "",
        dateReceived: "",
        hospitalName: "",
        hospitalStreetAddress: "",
        hospitalBarangay: "",
        hospitalCityMunicipality: "",
        hospitalProvince: "",
        hospitalPostalCode: "",
        numberOfLeaves: "",
        purpose: "Sickness",
        returnBy: "",
        status: "Pending",
        emailBody: "",
        attendingPhysician: "",
        supervisor: { email: "" },
        diagnosis: "",
        receivedBy: staffId,
        hasCustomFields: customFields.length > 0,
      }}
      onSubmit={handleSubmit}
      validationSchema={MedcertValidationSchema}
    >
      {formikHelpers => (
        <Form autoComplete="off" onKeyDown={handleKeyDown}>
          <Layout
            // hasSidebar
            pageTitle="New Medical Certificate"
            // sidebarContent={
            //   <EmployeeDetails
            //     siteId={appContext.siteDetails.id}
            //     {...formikHelpers}
            //   />
            // }
          >
            <Panel heading="Employee Details" icon={faUserTie}>
              <Employee
                appContext={appContext}
                {...formikHelpers}
                parentHasSidebar={false}
                isSupervisor={false}
              />
            </Panel>
            <Panel heading="Hospital Information" icon={faHospital}>
              <HospitalInformation formikHelpers={formikHelpers} />
            </Panel>
            <Panel
              heading="Medical Certificate Information"
              icon={faNotesMedical}
            >
              <MedcertInformation
                formikHelpers={formikHelpers}
                appContext={appContext}
              />
            </Panel>
            <Panel heading="Status" icon={faCheckDouble}>
              <MedcertStatus formikHelpers={formikHelpers} />
            </Panel>

            {Object.keys(customFields).length > 0 && (
              <Fragment>
                <Panel heading="Additional Information" icon={faInfoCircle}>
                  {customFields.map((item, index) => (
                    <CustomField
                      key={index}
                      customField={item}
                      emptyFields={emptyRequiredCustomFields}
                      values={formikHelpers.values}
                      errors={formikHelpers.errors}
                      hideAdditionalNotes
                    />
                  ))}
                </Panel>
              </Fragment>
            )}
            <div className="buttons is-pulled-right pb-5">
              <Link to="/medcerts" className="button is-danger is-outlined">
                <span className="icon">
                  <FontAwesomeIcon icon={faTimes} />
                </span>
                <span>Cancel</span>
              </Link>
              <button
                type="submit"
                className={classNames("button is-primary", {
                  "is-loading": isLoading,
                })}
              >
                <span className="icon">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
                <span>Save</span>
              </button>
            </div>
          </Layout>
        </Form>
      )}
    </Formik>
  );
};

export default MedcertForm;

import React, { useState, useContext, useEffect } from "react";
// import TextArea from "../../../components/Templates/Forms/Textarea";
import Input from "../../../components/Templates/Forms/Input";
import classNames from "classnames";
// import { Field } from "formik";
import moment from "moment";
import { sendEmail } from "../../../services/emailService";
import AppContext from "../../../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faHourglassHalf,
  faThumbsDown,
  faBan,
} from "@fortawesome/free-solid-svg-icons";

const STATUS_LIST = ["Pending", "Valid", "Not Valid", "Unable to Verify"];

const MedcertStatus = ({ formikHelpers, attendingNurse }) => {
  const [isActive, setActivebutton] = useState("Pending");
  const appContext = useContext(AppContext);
  let values = formikHelpers.values;

  const setValueStatus = (values, value, setFieldValue) => event => {
    values.status = value;
    setFieldValue("emailBody", getEmailBody(values, value));
    setActivebutton(value);
    return getEmailBody(values, value);
  };

  useEffect(() => {
    formikHelpers.setFieldValue("receivedBy", values.receivedBy);
    formikHelpers.setFieldValue("emailBody", getEmailBody(values, isActive));
  }, [
    appContext.userDetails,
    values.employee,
    values.supervisor.email,
    values.attendingPhysician,
    values.numberOfLeaves,
    values.returnBy,
    values.dateIssued,
  ]);

  // loading indicator for sending email
  const [isLoading, setLoadingIndicator] = useState(false);

  const getEmailBody = (values, value) => {
    let message = "";
    switch (value) {
      case "Pending":
      default:
        message = "";
        break;
      case "Valid":
        message = `Good day,\n\nPlease be informed that the medical certificate of ${
          values.employee.firstName
        } ${
          values.employee.lastName
        } has been validated. See details below:\n\nDate Medical Certificate Received by Clinic: ${moment().format(
          "MMMM D, YYYY"
        )}\n\nRequest for Validation Received by: ${
          values.nurseName
        }\n\nDate of Medical Certificate: ${moment(values.dateIssued).format(
          "MMMM D, YYYY"
        )}\n\nRecommendation: ${
          values.numberOfLeaves
            ? `(Validated by ${values.attendingPhysician}) `
            : values.returnBy
            ? `(Validated by ${values.attendingPhysician})`
            : ""
        }${
          values.numberOfLeaves
            ? `Rest for ${values.numberOfLeaves} day/s. `
            : ""
        }${values.returnBy &&
          ` Return by ${moment(values.returnBy).format(
            "MMMM D, YYYY"
          )}.`}\n\nDate Validated: ${moment().format(
          "MMMM D, YYYY"
        )}\n\nTime Validated: ${moment().format("hh:mm a")}\n\nValidated by: ${
          values.nurseName
        }\n\nResult of Validation: The submitted medical certificate is considered true & valid.`;
        break;
      case "Not Valid":
        message = `Good day,\n\nPlease be informed that the medical certificate of ${
          values.employee.firstName
        } ${
          values.employee.lastName
        } has been marked as invalid. See details below:\n\nDate Medical Certificate Received by Clinic: ${moment().format(
          "MMMM D, YYYY"
        )}\n\nRequest for Validation Received by: ${
          attendingNurse ? attendingNurse : values.nurseName
        }\n\nDate of Medical Certificate: ${moment(values.dateIssued).format(
          "MMMM D, YYYY"
        )}\n\nConfirmed by: ${
          attendingNurse ? attendingNurse : values.nurseName
        }\n\nResult: The submitted medical certificate is considered invalid.`;
        break;
    }
    return message;
  };

  const sendEmailClinic = (supervisor, nurse, employee, values, useToast) => {
    let subject = `Medical Certificate Validation for Employee (${
      employee.firstName
    } ${employee.lastName})`;

    let body = values.emailBody.split("\n\n").map((item, index) => {
      if (index < 2) return `<p>${item}</p><br/>`;
      return `<p><b>${item.replace(":", ":</b>")}</p>`;
    });

    let message = `
    <!DOCTYPE html>
    <html>
      <head></head>
      <style>1
        @media only screen and (max-width: 770px) {
          .logo {
            width: 100%;
          }
        }
        @media screen {
          .logo {
            width: 30%;
          }
        }
        body {
          font-family: Lato, sans-serif;
        }
        .content {
          width: 80%;
        }
        .is-primary {
          color: #0d6d6e;
        }
        .is-bordered {
          border: 1px solid black;
        }
        .is-right {
          text-align: right;
        }
        .is-center {
          text-align: center;
        }
        .no-wrap{
          white-space: nowrap;
        }
      </style>
      <body>
      ${body.join("")}
      </body>
    </html>`;

    // sendEmail(supervisor.email, nurse, subject, message, useToast).then(() =>
    //   setLoadingIndicator(false)
    // )
    sendEmail(supervisor.email, nurse, subject, message, useToast).then(() => {
      if (values.otherRecipients)
        sendEmail(
          values.otherRecipients,
          nurse,
          subject,
          message,
          useToast
        ).then(() => setLoadingIndicator(false));
      else setLoadingIndicator(false);
    });
  };

  const handleSubmit = () => {
    sendEmailClinic(
      formikHelpers.values.supervisor,
      appContext.userDetails.email,
      formikHelpers.values.employee,
      formikHelpers.values,
      appContext.useToast
    );
    setLoadingIndicator(true);
  };

  const getIcon = status => {
    switch (status) {
      case "Pending":
        return faHourglassHalf;
      case "Valid":
        return faThumbsUp;
      case "Not Valid":
        return faThumbsDown;
      case "Unable to Verify":
        return faBan;
      default:
        return;
    }
  };

  return (
    <>
      <div className="columns">
        <div className="column pb-0 is-3">
          <div className="field has-addons">
            {STATUS_LIST.map((status, index) => (
              <p className="control">
                <button
                  className={classNames("button", {
                    "is-warning has-text-weight-bold":
                      isActive === "Pending" && isActive === status,
                    "is-primary has-text-weight-bold":
                      isActive === "Valid" && isActive === status,
                    "is-light has-text-weight-bold":
                      isActive === "Not Valid" && isActive === status,
                    "is-danger has-text-weight-bold":
                      isActive === "Unable to Verify" && isActive === status,
                  })}
                  key={index}
                  type="button"
                  onClick={setValueStatus(
                    formikHelpers.values,
                    status,
                    formikHelpers.setFieldValue
                  )}
                >
                  <span className="icon">
                    <FontAwesomeIcon icon={getIcon(status)} />
                  </span>
                  <span>{status}</span>
                </button>
              </p>
            ))}
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-6 pb-0">
          {isActive !== "Valid" && (
            <Input name="notes" label={`Reason For ${isActive} Status`} />
          )}
        </div>
      </div>
      {/* {isActive !== "Pending" && (
        <Fragment>
          <div className="columns">
            <div className="column py-0">
              <Input
                type="text"
                name="otherRecipients"
                value="otherRecipients"
                label="Additional Email Recipients"
              />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <Field name="emailBody">
                {({ field, form }) => (
                  <TextArea
                    field={field}
                    label="Email Body (please edit as needed)"
                  />
                )}
              </Field>
              <button
                id="sendEmailButton"
                type="button"
                className={classnames("button is-primary", {
                  "is-loading": isLoading === true,
                })}
                onClick={handleSubmit}
              >
                Send Email
              </button>
              <span className="tag is-size-6 has-text-grey-darker has-background-white">
                to: {values.supervisor.email}
                {values.otherRecipients ? `, ${values.otherRecipients}` : ""}
              </span>
            </div>
          </div>
        </Fragment>
      )} */}
    </>
  );
};

export default MedcertStatus;

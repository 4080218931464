import React, { Fragment, useState, useEffect } from "react";
import Input from "../../../components/Templates/Forms/Input";
import SearchBar from "../../../components/Templates/Forms/SearchBar";

import Employee from "../../Consultation/ConsultationForm/Employee";
import { Field } from "formik";
import Datalist from "../../Templates/Forms/Datalist";
import api from "../../../services/api";

const MedcertInformation = ({ formikHelpers, appContext }) => {
  const [clinicStaff, setClinicStaff] = useState([]);
  const MEDCERT_PURPOSE_OPTIONS = [
    {
      label: "Sickness",
      value: "Sickness",
    },
    {
      label: "Magna Carta",
      value: "Magna Carta",
    },
    {
      label: "Maternity leave",
      value: "Maternity leave",
    },
  ];

  useEffect(() => {
    let tempClinicStaff = [];
    const clinicId = sessionStorage.getItem("clinicId");

    api.get(`/staff/?clinic__id=${clinicId}`).then(response => {
      response.data.results.map(staff => {
        tempClinicStaff.push({
          value: staff.id,
          label: `${staff.firstName} ${staff.lastName}`,
        });
      });
    });

    setClinicStaff(tempClinicStaff);
  }, []);

  return (
    <Fragment>
      <div className="columns">
        <div className="column pb-0">
          <Input type="date" name="dateIssued" label="Date Issued" isRequired />
        </div>
        <div className="column pb-0">
          <Input
            type="date"
            name="dateReceived"
            label="Date Received"
            isRequired
          />
        </div>
      </div>
      <div className="columns">
        <div className="column pb-0">
          <Field name="purpose">
            {({ field, form }) => (
              <Datalist
                field={field}
                name={field.name}
                hasLabel
                placeholder="Select from list below..."
                options={MEDCERT_PURPOSE_OPTIONS}
                name="purpose"
                label="Purpose"
                isRequired
                defaultValue={MEDCERT_PURPOSE_OPTIONS[0]}
                onChange={selected => {
                  form.setFieldValue(field.name, selected.value);
                }}
                errors={formikHelpers.errors}
              />
            )}
          </Field>
        </div>
        <div className="column pb-0">
          <Field name="receivedBy">
            {({ field, form }) => (
              <Datalist
                field={field}
                name={field.name}
                hasLabel
                placeholder="Select from list below..."
                options={clinicStaff}
                name="receivedBy"
                label="Received by"
                isRequired
                defaultValue={{
                  value: sessionStorage.getItem("staffId"),
                  label: sessionStorage.getItem("staffName"),
                }}
                onChange={selected => {
                  form.setFieldValue(field.name, selected.value);
                }}
              />
            )}
          </Field>
        </div>
      </div>
      <div className="columns">
        <div className="column py-0">
          <Input
            isRequired
            name="attendingPhysician"
            label="Attending Physician"
            render={({ field, form, props }) => {
              return (
                <SearchBar
                  endpoint="medcerts/physicians"
                  resultDisplay={["attendingPhysician"]}
                  field={field}
                  form={form}
                  props={props}
                  isAutocomplete={true}
                />
              );
            }}
          />
        </div>
        <div className="column py-0">
          <Input
            isRequired
            name="diagnosis"
            label="Diagnosis"
            render={({ field, form, props }) => {
              return (
                <SearchBar
                  endpoint="medcerts/diagnosis"
                  resultDisplay={["diagnosis"]}
                  field={field}
                  form={form}
                  props={props}
                  isAutocomplete={true}
                />
              );
            }}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column py-0">
          <Input type="date" name="returnBy" label="Fit To Work By" />
        </div>
        <div className="column py-0">
          <Input
            type="number"
            min={0}
            name="numberOfLeaves"
            placeholder="1"
            label="Recommended Number Of Leaves"
          />
        </div>
      </div>
      <div className="columns">
        <div className="column py-0">
          <Employee
            appContext={appContext}
            {...formikHelpers}
            isSupervisor={true}
            parentHasSidebar={false}
          />
        </div>
        <div className="column py-0">
          <Input type="text" name="supervisorEmail" label="Supervisor Email" />
        </div>
      </div>
    </Fragment>
  );
};

export default MedcertInformation;

import React, { useContext, useState, useEffect, Fragment } from "react";
import moment from "moment";
import api from "../../services/api";
import MedcertForm from "../../components/Medcerts/MedcertForm";
import AppContext from "../../context/AppContext";
import { navigate } from "gatsby";
import SEO from "../../layout/Seo";
import { generateCustomFieldsValue } from "../../services/consultationUtils";

const AddMedcert = () => {
  const [isLoading, setLoading] = useState(false);
  const [emptyRequiredCustomFields, setEmptyRequiredCustomFields] = useState(
    []
  );
  const [customFields, setCustomFields] = useState([]);
  const [clientCode, setClientCode] = useState();

  const appContext = useContext(AppContext);

  useEffect(() => {
    let tempClientCode = sessionStorage.getItem("clientCode");
    setClientCode(tempClientCode);

    // I tried editing `tempClientCode` below to `clientCode`
    // to avoid the warning log of "clientCode defined but not used"

    // However if you console.log(clientCode, tempClientCode)
    // it will show the ff result:
    // undefined "MG"

    // ...so, yeah. Lesson: Don't be trigger-happy with clearing logs. Also, maybe
    //  sessionStorage's getItem isn't as fast as setState (??? for inspection)

    // Update 201021: Gurl, useEffect is async. clientCode won't update immediately,
    // yielding the undefined variable haha

    api
      .get(`custom-fields/?client=${tempClientCode}&model=medcert`)
      .then(response => {
        setCustomFields(response.data);
      });
  }, []);

  const reconstructValues = (values, customFieldsValues) => {
    return {
      // required fields
      dateReceived: moment()
        .utc()
        .format(),
      dateIssued: values.dateIssued,
      employee: {
        individual_id: String(values.employee.id),
        department: values.employee.employee.department,
        name: `${values.employee.firstName} ${values.employee.lastName}`,
      },
      supervisor: String(values.supervisor.id),
      status: values.status,
      hospitalName: values.hospitalName,
      attendingPhysician: values.attendingPhysician,
      diagnosis: values.diagnosis,
      receivedBy: sessionStorage.getItem("staffId"),
      clinic: sessionStorage.getItem("clinicId"),
      purpose: values.purpose,

      // nullable or not required fields
      returnBy: values.returnBy || null,
      numberOfLeaves: values.numberOfLeaves || null,
      notes: values.notes || null,
      hospitalStreetAddress: values.hospitalStreetAddress || null,
      hospitalBarangay: values.hospitalBarangay || null,
      hospitalCityMunicipality: values.hospitalCityMunicipality || null,
      hospitalProvince: values.hospitalProvince || null,
      hospitalPostalCode: values.hospitalPostalCode || null,
      customFields: customFieldsValues,
    };
  };

  const handleSubmit = toast => values => {
    if (!values.employee.firstName) {
      toast("Please select an employee.", "is-warning", 5000);
      return;
    }
    setLoading(true);
    let customFieldsDict = {};
    let tempEmptyRequiredFields = [];

    customFields.map(item => {
      if (values[item.name])
        customFieldsDict[item.name] = generateCustomFieldsValue(
          values,
          item.name,
          item.dataType
        );
      else if (item.isRequired) tempEmptyRequiredFields.push(item.name);
    });

    if (tempEmptyRequiredFields.length > 0) {
      setEmptyRequiredCustomFields(tempEmptyRequiredFields);
      setLoading(false);
    }

    return api
      .post("/medcerts/", reconstructValues(values, customFieldsDict))
      .then(response => {
        setLoading(false);
        toast(
          `Successfully saved medcert validation for ${
            values.employee.firstName
          } ${values.employee.lastName}.`,
          "is-success",
          5000
        );
        navigate("/medcerts");
      })
      .catch(error => {
        setLoading(false);
        toast(
          "An error occurred. Please double check your data.",
          "is-danger",
          5000
        );
        console.log(error);
      });
  };

  return (
    <Fragment>
      <SEO title="Add New MedCert" />
      <MedcertForm
        handleSubmit={handleSubmit(appContext.useToast)}
        appContext={appContext}
        isLoading={isLoading}
        customFields={customFields}
        emptyRequiredCustomFields={emptyRequiredCustomFields}
      />
    </Fragment>
  );
};

export default AddMedcert;
